.left-section {
  max-width: 500px;
  margin-right: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'ChiRho', Times, 'Times New Roman', serif;
  font-size: 36px;
}

.content{ 
    background: url('../static/images/marcus.jpg') no-repeat center center fixed;
    background-size: cover;
}

.title {
  text-align: center;
  font-size: 2rem;
  color: #333;
}

/* Form styles */
.birthday-form {
  margin-top: 20px;
  text-align: left;
}

.label {
  font-size: 1rem;
  font-weight: bold;
}

.subtext {
  font-size: 0.9rem;
  color: #666;
}

.birthday-input {
  margin-top: 10px;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.submit-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #880808;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #e74c31;
}

/* Stats section */
.stats {
  margin-top: 20px;
}

.stat {
  font-size: 1.2rem;
  color: #333;
}

.stat span {
  font-weight: bold;
}

/* Canvas styles */
.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-width: 800px;
  width: 100%;
  margin-left: 20px;
}

.circle-canvas {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100vh;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 2px;
}

#circleCanvas {
  display: block;
  margin: 0 auto;
  width: 100%;
}