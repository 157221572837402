.sidebar {
  height: 100vh;
  padding: 10px;
  transition: width 0.3s ease;
  overflow-x: hidden;
}

.sidebar button {
  background: none;
  border: none;
  font-size: 24px;
  margin-bottom: 20px;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  transition: background-color 0.2s ease;
}

.nav-link:hover {
  background-color: #f8f9fa;
  border-radius: 5px;
}
