@font-face {
  font-family: 'ChiRho';
  src: local('ChiRho'), url(static/fonts/Constantine.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'ChiRho';
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.header {
  padding: 20px;
}
.d-flex{
  background-color: black;
}
textarea {
  width: 100%;
  height: 200px;
}

/* General content layout */
.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  flex-wrap: wrap;
  overflow: auto;
}

.middle-section {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
}
