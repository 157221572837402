/* Quotes.css */

/* General container for the entire component */
.quote-container {
  display: flex;
  justify-content: center; /* Center the card horizontally */
  padding: 16px;
  align-items: center; /* Vertically center content */
  flex-direction: column;
}

/* Styling for the quote card */
.quote-card {
  position: relative;
  background-color: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Set a max-width to make the card thinner */
  width: 100%; /* Allow it to scale down for smaller screens */
  min-height: 400px; /* Set a fixed minimum height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding-top: 50px; /* Add space at the top for the buttons */
  padding-bottom: 50px; /* Add space at the bottom for the buttons */
  box-sizing: border-box; /* Include padding in total width */
  overflow: visible; /* Ensure elements outside are visible */
}

/* Styling for the content inside the card */
.quote-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative; /* Allow navigation buttons to sit outside */
  z-index: 1; /* Ensure text is above the buttons */
  width: 100%; /* Ensure content takes full width */
  height: 100%; /* Ensure content takes full height */
  overflow-y: auto; /* Add scrollbar if content overflows vertically */
  padding: 8px; /* Add some padding to prevent text from touching edges */
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-text {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  max-width: 100%; /* Ensure text doesn't overflow horizontally */
  margin: 0; /* Remove default margin */
}

.quote-author {
  color: #4b5563; /* Gray text */
  margin: 0; /* Remove default margin */
}

/* Navigation buttons for previous and next quotes */
.quote-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d1d5db; /* Grey background for the buttons */
  border-radius: 50%;
  width: 40px; /* Increase size for visibility */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10; /* Ensure buttons are above the card content */
  font-size: 1.5rem; /* Make arrow symbols bigger */
}

.prev-button {
  left: -50px; /* Move the previous button slightly outside the card */
}

.next-button {
  right: -50px; /* Move the next button slightly outside the card */
}

.quote-nav-button:hover {
  background-color: #a1a1aa; /* Darker gray on hover */
}

.quote-index {
  text-align: center;
  margin-top: 10px;
  font-size: 1.2em;
  color: #555;
}

/* Celebration message when reaching the last quote */
.congrats-message {
  text-align: center;
  animation: celebrate 2s ease-in-out infinite;
}

@keyframes celebrate {
  0% {
    transform: scale(1);
    color: #ff6f61;
  }
  25% {
    transform: scale(1.2);
    color: #6b5b95;
  }
  50% {
    transform: scale(1);
    color: #88b04b;
  }
  75% {
    transform: scale(1.2);
    color: #f7cac9;
  }
  100% {
    transform: scale(1);
    color: #ff6f61;
  }
}

/* Additional styling for the congrats message */
.congrats-message h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.congrats-message p {
  font-size: 1.5em;
  color: #555;
}
